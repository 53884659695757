define(['isMobileDevice', 'uaParser'], (isMobileDevice, uaParser) => {

  const a2HSToggle = () => {

    const component = {};

    component.config = {
      iOSModalButton: '.a2HSToggle_a2HSiOSModal',
      googleButton: '.a2HSToggle_a2HSGoogle',
      a2HSToggleListItem: '.responsiveSubMenu_subMenuLinkListItem_a2HSToggle'
    };
    
    component.init = (element) => {
      component.element = element;
      component.iOSA2HSButton = component.element.querySelector(component.config.iOSModalButton);
      component.userAgent = navigator.userAgent;
      component.mobileUserAgent = isMobileDevice.any(component.userAgent);
      component.iOSUserAgent = isMobileDevice.ios(component.userAgent);
      component._uaParser = new uaParser();
      component.uaBrowserName = component._uaParser.getResult().browser.name;
      component.googleA2HSButton = component.element.querySelector(component.config.googleButton);
      component.a2HSToggleListItem = Array.from(document.querySelectorAll(component.config.a2HSToggleListItem));
      component.deferredPrompt = null;

      component.toggle();
      return component;
    };

    component.toggle = () => {
      if(component.iOSUserAgent) {
       component.iOSa2HS();
      } else if (component.mobileUserAgent || component.isChrome()) {
       component.googlea2HS();
      }
    };

    component.iOSa2HS = () => {
      if(component.iOSA2HSButton  && component.a2HSToggleListItem) {
        component.a2HSToggleListItem.forEach(item => {
          item.style.display = 'block';
        })

        component.iOSA2HSButton.style.display = 'block';
      }
    };

    component.googlea2HS = () => {
      window.addEventListener('beforeinstallprompt', (e) => {
        e.preventDefault();
        component.deferredPrompt = e;

        if(component.googleA2HSButton  && component.a2HSToggleListItem) {
          component.a2HSToggleListItem.forEach(item => {
            item.style.display = 'block';
          })

          component.googleA2HSButton.style.display = 'block';
        }
      });

      if (component.googleA2HSButton  && component.a2HSToggleListItem) {
        component.googleA2HSButton.addEventListener('click', () => {
          component.a2HSToggleListItem.forEach(item => {
            item.style.display = 'none';
          })

          component.deferredPrompt.prompt();
          component.deferredPrompt.userChoice.then(function(choiceResult) {});
          component.deferredPrompt = null;
        });
      }
    };
    
    component.isChrome = () => {
      return ["Chrome WebView", "Chrome", "Chromium", "Chrome Headless"]
          .indexOf(component.uaBrowserName) !== -1;
    };
    
    return component;
  };

  return a2HSToggle;
});
